import React from 'react'
import Layout from "../components/layout";
import Seo from "../components/SEO/Seo"
import { graphql } from 'gatsby'
import "../styles/singleProyecto.scss"
import { FiArrowUpRight } from "react-icons/fi"



export default function Post({ data, pageContext }) {

  const datos = data.strapiProyecto;
  const cms = `https://api.bullhost.es`;
  const urlDestacada = `${cms}${datos.media_principal.url}`
  const media2 = `${cms}${datos.media_2.url}`
  const media3 = `${cms}${datos.media_3.url}`

  return (
    <Layout>
      <Seo
        title={datos.titulo}
        imagen={urlDestacada}
        pathname={`/proyecto/${pageContext.slug}`}
        description={datos.subtitulo}
      />
      <div className="meta-proyecto" style={{ backgroundImage: `url(${urlDestacada})` }}>
        {/* <img src={urlDestacada} alt={datos.media_principal.alternativeText} /> */}
        <div className="textos">
          <div className="palabra-clave">{datos.palabra_clave}</div>
          <h1>{datos.titulo}</h1>
          <div className="subtitulo">{datos.subtitulo}</div>
        </div>
      </div>
      <section className="container">
        <div className="cuerpo-proyecto">
          <div className="bloque-texto">
            <div className="col-left">
              <h2>{datos.copy_grande_1}</h2>
            </div>
            <div className="col-right">
              <div dangerouslySetInnerHTML={{ __html: datos.parrafo_1 }}></div>
            </div>
          </div>
          <div className="segunda-media bloque-foto">
            {datos.media_2.ext === ".mp4" ?
              <video src={media2} controls></video> :
              <img src={media2} alt="" />
            }
          </div>
          <div className="bloque-texto">
            <div className="col-left">
              <h2>{datos.copy_grande_2}</h2>
            </div>
            <div className="col-right">
              <div dangerouslySetInnerHTML={{ __html: datos.parrafo_2 }}></div>
            </div>
          </div>
          <div className="tercera-media bloque-foto">
            {datos.media_3.ext === ".mp4" ?
              <video src={media3} controls></video> :
              <img src={media3} alt="" />
            }
          </div>
          <div className="link-proyecto">
            <a href={datos.web_proyecto}>Visita la web del proyecto <FiArrowUpRight /></a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query ($slug: String!){
      strapiProyecto(slug:{eq: $slug}){
    	titulo
        subtitulo
        copy_grande_1
        copy_grande_2
        parrafo_1
        parrafo_2
        palabra_clave
        web_proyecto
        media_principal {
          url
          ext
        }
        media_2 {
          url
          ext
        }
        media_3 {
          url
          ext
        }
          }
        }
  `
